<template>
    <div>
        <v-col cols="12" sm="4">
            <h4>Listado de Pacientes</h4>
        </v-col>
        <v-row align="center" class="list px-3 mx-auto">
            <v-col cols="12" sm="8">
                <v-text-field clearable v-model="searchApellido" label="Buscar por Apellido"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
                <v-btn @click="page = 1; retrievePacientes();">
                    Buscar
                </v-btn>
                
                 <!-- TODO implement clear and reload search
                    <v-btn @click="clearAndRretrievePacientes();">
                            Recargar
                 </v-btn> -->
            </v-col>
                
            <v-col cols="12" sm="12">
                <v-row>
                    <v-col cols="4" sm="3">
                        <v-select v-model="pageSize" :items="pageSizes" label="Pacientes por Pagina"
                            @change="handlePageSizeChange"></v-select>
                    </v-col>
                    <v-col cols="12" sm="9">
                        <v-pagination v-model="page" :length="totalPages" total-visible="7" next-icon="mdi-menu-right"
                            prev-icon="mdi-menu-left" @input="handlePageChange"></v-pagination>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" sm="12">
                <v-card class="mx-auto" tile>
                    <v-card-title>Pacientes</v-card-title>
                    <v-data-table :headers="headers" :items="pacientes" disable-pagination :hide-default-footer="true"
                        :custom-sort="customSort">
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon color="blue" class="mr-2"
                                        @click="editarPaciente(item.id)">
                                        <v-icon small>mdi-pencil</v-icon>
                                    </v-btn>
                                </template>
                                <span>Editar informacion de paciente</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon color="green" class="mr-2"
                                        @click="agregarConsulta(item.id)">
                                        <v-icon small>mdi-account-details</v-icon>
                                    </v-btn>
                                </template>
                                <span>Agregar registro de Consulta</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon color="grey" class="mr-2"
                                        @click="verConsultas(item.id)">
                                        <v-icon small>mdi-account-eye</v-icon>
                                    </v-btn>
                                </template>
                                <span>Ver historial de Consultas</span>
                            </v-tooltip>
                            <!-- <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon color="red" class="mr-2"
                                        @click="(deleteConfirmation(item.id))">
                                        <v-icon small>mdi-account-remove</v-icon>
                                    </v-btn>
                                </template>
                                <span>Eliminar este paciente</span>
                            </v-tooltip>
                            <confirm-dialogue ref="confirmDialogue"></confirm-dialogue> -->
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import PacienteDataService from "../services/PacienteDataService";
//import ConfirmDialogue from '../components/ConfirmDialogue.vue';

export default {

    name: "pacientes-list",
    components: {
        //ConfirmDialogue
    },
    data() {
        return {
            dialog: false,
            pacientes: [],
            searchApellido: "",
            headers: [{
                text: "Apellido",
                align: "start",
                sortable: true,
                value: "apellido"
            },
            {
                text: "Nombre",
                value: "nombre",
                sortable: true
            },
            {
                text: "Autoriza Material",
                value: "materialautorizado",
                sortable: true
            },
            {
                text: "Activo",
                value: "pacienteactivo",
                sortable: true
            },
            {
                text: "Fecha Modificacion",
                value: "fechaupdate",
                sortable: true
            },
            {
                text: "Acciones",
                value: "actions",
                sortable: false
            },
            ],
            page: 1,
            totalPages: 0,
            pageSize: 12,
            pageSizes: [12, 24, 48],
        };
    },
    methods: {

        async deleteConfirmation(idToDelete) {
            const ok = await this.$refs.confirmDialogue.show({
                title: 'Desea eliminar el paciente?',
                message: 'Esta accion no se puede deshacer.',
                okButton: 'Eliminar',
            })
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            if (ok) {
                this.deletePaciente(idToDelete)
            } else {
                //alert('You chose not to delete this page. Doing nothing now.')
            }
        },

        deletePaciente(id) {
            PacienteDataService.delete(id)
                .then(() => {
                    this.refreshList();
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        //customSort: the native sort function of the data view table doesn't works as it should, this custom implementation solves it
        customSort: function (items, index, isDesc) {
            items.sort((a, b) => {
                if (index[0] == 'date') {
                    if (!isDesc[0]) {
                        return new Date(b[index]) - new Date(a[index]);
                    } else {
                        return new Date(a[index]) - new Date(b[index]);
                    }
                } else {
                    if (typeof a[index] !== 'undefined') {
                        if (!isDesc[0]) {
                            return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
                        } else {
                            return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
                        }
                    }
                }
                console.log(items);
            });
            return items;

        },
        getRequestParams(searchApellido, page, pageSize) {
            let params = {};
            if (searchApellido) {
                params["apellido"] = searchApellido;
            }
            if (page) {
                params["page"] = page - 1;
            }
            if (pageSize) {
                params["size"] = pageSize;
            }
            return params;
        },
        retrievePacientes() {
            const params = this.getRequestParams(
                this.searchApellido,
                this.page,
                this.pageSize
            );
            PacienteDataService.getAll(params)
                .then((response) => {
                    const {
                        pacientes,
                        totalPages
                    } = response.data;
                    this.pacientes = pacientes.map(this.getDisplayPaciente);
                    this.totalPages = totalPages;
                    console.log(response.data);
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        handlePageChange(value) {
            this.page = value;
            this.retrievePacientes();
        },
        handlePageSizeChange(size) {
            this.pageSize = size;
            this.page = 1;
            this.retrievePacientes();
        },
        getDisplayPaciente(paciente) {
            return {
                id: paciente.id,
                apellido: paciente.apellido.substr(0, 40),
                nombre: paciente.nombre.substr(0, 40),
                materialautorizado: paciente.materialautorizado ? "Si" : "No",
                pacienteactivo: paciente.pacienteactivo ? "Activo" : "Inactivo",
                fechaupdate: paciente.updatedAt.substring(0, 10),
            };
        },
        refreshList() {
            this.retrievePacientes();
        },
        editarPaciente(id) {
            this.$router.push({
                name: "paciente-details",
                params: {
                    id: id
                }
            });
        },
        agregarConsulta(id) {
            this.$router.push({
                name: "consulta",
                params: {
                    id: id
                }
            });
        },
        verConsultas(id) {
            this.$router.push({
                name: "consultaList",
                params: {
                    id: id
                }
            });
        },
    },
    mounted() {
        this.retrievePacientes();
    },
};
</script>
